import React from 'react';
import algoliasearch from 'algoliasearch';
import './AddEquipmentPage.css';
import { Grid, Button} from 'semantic-ui-react'
import EquipmentList from './EquipmentList/EquipmentList.component';
import AutoCompleteSearchField from './AutoCompleteSearchField/AutoCompleteSearchField.component';
import AutoCompleteSearchResults from './AutoCompleteSearchResults/AutoCompleteSearchResults.component';
import { InstantSearch } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { addToAddedEquipmentList } from '../../../redux/addedEquipmentList/addedEquipmentList.actions';
//import FilmTingButton from '../../shared/filmting-button/filmting-button.component.jsx';

const searchClient = algoliasearch('F87EOUNNBF', '8de6d169bd84abd757849fcd96579dd6');

export default function AddEquipmentPage() {

  const addedEquipment = useSelector(state => state.addedEquipmentList);

  return (
    <main>
        <h1>Søk etter utstyr å legge til i din utstyrspark</h1>
        <InstantSearch searchClient={searchClient} indexName="products">
          <Grid stackable columns={2}>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={10} computer={12}>
                <div className="resizedSearchBoxContainer">
                  <div className="topBar">
                    <AutoCompleteSearchField></AutoCompleteSearchField>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={4}>
                {/*Needed for correct alignment of Search box*/}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row divided className="searchResultsContainer">
              <Grid.Column mobile={16} tablet={10} computer={12}>
                <AutoCompleteSearchResults actionToDispatchOnAddEquipmentClick={addToAddedEquipmentList}></AutoCompleteSearchResults>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={4}>
                <h2 className="addedEquipmentListTitle">Valgt utstyr</h2>
                <EquipmentList addedEquipment={addedEquipment}></EquipmentList>
                {(addedEquipment.length > 0) && <Button className='saveButton' color='green' size='large'>LEGG TIL UTSTYRET I UTSTYRSPARKEN</Button>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </InstantSearch>
      </main>
  )
}

//DOMContentLoaded is not supported in IE8
document.addEventListener("DOMContentLoaded", function(event) { 
  positionSearchField();
  moveSearchFieldOnWindowResize();
});

function positionSearchField() {
      
  var element = document.getElementById("crawledEquipmentList");
  if (element === null) {
      return;
  }
  var listWidth = element.clientWidth;

  const baseWidth = 310;
  var howManyTimesLarger = getHowManyMultiplesOfBaseWidth(baseWidth, listWidth);
  var inputFieldWidthInPx;

  var divsToResize = document.getElementsByClassName("resizedSearchBoxContainer");
  if (howManyTimesLarger >= 1) {
      inputFieldWidthInPx = (baseWidth*howManyTimesLarger);
  }
  else {
      for (let i = 0; i < divsToResize.length; i++) {
          divsToResize.item(i).style.marginLeft = 0+"px";
      }
  }
  
  
  for (let i = 0; i < divsToResize.length; i++) {
      divsToResize.item(i).style.width = inputFieldWidthInPx+"px";
      divsToResize.item(i).style.marginLeft = "auto";
  }
}

function getHowManyMultiplesOfBaseWidth(baseWidth, listWidth) {
  var howManyTimesLarger = listWidth/baseWidth;
  return Math.floor(howManyTimesLarger)
}

function moveSearchFieldOnWindowResize() {
  const ro = new ResizeObserver(entries => {
    positionSearchField();
  });
  var unorderedListElement = document.getElementById('crawledEquipmentList')
  if (unorderedListElement != null) {
      ro.observe(document.getElementById('crawledEquipmentList')); 
  }  
}