import React from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import EquipmentCard from '../EquipmentCard/EquipmentCard.component.jsx'; 
import { useDispatch } from 'react-redux';

//connectAutoComplete passes a few useful props, like hits.
function HitsList(props) {
    const dispatch = useDispatch(); //TODO: See if this can be moved one level up in the component hiarchy
    return (
        <ul className="autoCompleteList" id="crawledEquipmentList">
            {props.hits.map(hit => (
                <EquipmentCard equipment={hit} key={hit.objectID} addEquipmentClicked={() => dispatch(props.actionToDispatchOnAddEquipmentClick(hit))}></EquipmentCard>
            ))}
        </ul>
    );
}

const AutoCompleteSearchResults = connectAutoComplete(HitsList);

//This component must be inside an Algolia InstantSearch component
export default AutoCompleteSearchResults;
