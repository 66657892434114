import React, { Component } from 'react';
import logo from '../../../assets/filmting-svg-logo-400x400.svg';
import { Segment, Container, Header, List, Grid, Image, Icon, Card, Tab, Divider } from 'semantic-ui-react';
import SignUp from '../../shared/register/register.component';
import './welcomepage.styles.scss';
import { Link } from 'react-router-dom';
import FilmTingButton from '../../shared/filmting-button/filmting-button.component';
import { analytics } from '../../../firebase/firebase.utils';

const panes = [
  { menuItem: 'Bilder og Utstyrsspesifikasjoner', render: () => <Tab.Pane>Du trenger ikke fotografere alt utstyret ditt, vi har en database med bilder og tekniske spesifikasjoner. Du trenger bare å skrive navnet på produktet, også fikser vi resten.</Tab.Pane> },
  { menuItem: 'Oversikt over tilgjengelighet', render: () => <Tab.Pane>Vi tilbyr en kalenderoversikt der du lett kan legge inn når utstyret brukes internt, og dermed når det er tilgjengelig eller utilgjengelig for utleie. Kalenderen kan også brukes for å holde oversikt  over tilgjengelighet av utstyr i forbindelse med interne prosjekt og produksjoner.</Tab.Pane> },
  { menuItem: 'Fakturering og Regnskap', render: () => <Tab.Pane>Kobling til regnskapsprogram for enkel fakturering, momsregistrering og avskrivning av skatt.</Tab.Pane> },
  { menuItem: 'Utleiesystem', render: () => <Tab.Pane>Istedenfor at alle setter opp og betaler for hvert sitt system for utleie, så lager vi ett som fungerer for alle sammen. Da sparer vi alle både tid og penger.</Tab.Pane> },
  { menuItem: 'Utleieforsikring', render: () => <Tab.Pane>Vi vil tilby automatisk forsikring ved å leie ut via FilmTing.</Tab.Pane> },
  { menuItem: 'Markedsføring', render: () => <Tab.Pane>Vi tar oss av markedsføring og profilering av utstyret du legger inn på FilmTing.</Tab.Pane> }
]



export class WelcomePage extends Component {
  constructor() {
    super();



    this.state = {
      string: '',
    };
  }

  async componentDidMount() {
    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }
    this.intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const variant = window.google_optimize.get("EgSHaKPKQWu-QITciGAVaA");
        this.setState({ variant });
        clearInterval(this.intervalId);
      }
    }, 100);
  }




  render() {
    return (
      <div className='main-wrapper'>
        <Grid columns={2} stackable >
          <Grid.Column >
            <div className="filmting-logo-img">
              <img src={logo} alt="filmting-logo" height="400px" />
            </div>
          </Grid.Column>
          <Grid.Column className="section-1B">
            <div className="introtext">
              <Header size="huge">FilmTing gjør utleie enklere.</Header>
              <h4>
                Vår visjon er å fjerne hindringene som står i veien fra ide til
                ferdig film. Vi trenger rett og slett en ryddigere og mer
                profesjonell ordning for utstyrsleie. Bedre sikkerhet,
                rimeligere forsikringsavtale, økt synlighet og oversikt over
                tilgjengelig utstyr.
              </h4>
              <div className="welcomebutton">
                <Link to="/registrer">
                  <FilmTingButton
                    onClick={analytics.logEvent("sign-up-button-clicked", {
                      location: "welcome-header",
                      action: "signup-interest",
                    })}
                  >
                    <Icon name="signup" />
                    Prøv filmting gratis
                  </FilmTingButton>
                </Link>
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid columns={2} stackable className="segmentmargin">
          <Grid.Column className="image first mobilehidden">
            <div></div>
          </Grid.Column>
          <Grid.Column>
            <div className="columnmargin">
              <Header size="large">Bli med på å skape fremtiden</Header>
              <p>
                Vi i FilmTing søker premissleverandører, både deg som driver med
                utleie, og deg som leier utstyr, uansett hvor stor eller liten
                skala det er snakk om. Vi trenger folk fra hele spekteret, så vi
                kan dekke alles behov, og lage en tjeneste som hjelper alle.
              </p>
              <Header size="large">Hva innebærer dette å melde seg på?</Header>
              <p>
                Som premissleverandør får du som første aktør bruke løsningen
                gratis, og før alle andre i bransjen.
              </p>
              <p>
                Vi kommer til å bygge ut tjenesten gradvis, og ønsker ditt
                innspill om forbedringer som vil gjøre hverdagen din enklere.
              </p>
              <Header size="large">Koster det noe?</Header>
              <p>
                Tjenesten er gratis å bruke. Det vil si at du har ingenting å
                tape på å bruke plattformen.
              </p>
              <p>
                Vi tar kun en provisjon på 3% av det du får inn via plattformen
                i prøveperioden. Provisjonen benyttes til å utvikle løsningen og
                ny funksjonalitet basert på dine tilbakemeldinger.
              </p>
            </div>
          </Grid.Column>
        </Grid>

        <Segment className="utvikles logocorners segmentmargin">
          <Grid columns={2} stackable>
            <Grid.Column>
              <Container>
                <Header size="medium">Prosjektet utvikles av </Header>
                <Image
                  target="_blank"
                  href="https://www.ragnarokfilm.no"
                  centered
                  size="small"
                  src="https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2FOriginal%404x%20Blacktext.png?alt=media&token=53fa0901-35a3-4fb4-bc55-1819d490a4aa"
                />
              </Container>
            </Grid.Column>

            <Grid.Column>
              <Container>
                <Header size="medium">Støttes av</Header>
                <Image
                  target="_blank"
                  href="https://www.innovasjonnorge.no/"
                  centered
                  size="small"
                  src="https://firebasestorage.googleapis.com/v0/b/filmting-dev.appspot.com/o/img%2Finnovasjon-norge-logo-300x104.png?alt=media&token=d8601c7c-ee9a-4b11-861a-03d31641abea"
                />
              </Container>
            </Grid.Column>
          </Grid>
          <Divider vertical className="mobilehidden">
            og
          </Divider>
        </Segment>

        <Grid columns={2} stackable className="segmentmargin">
          <Grid.Column width={10}>
            <Segment>
              <Header size="large">
                Krever det mye tid og arbeid å drive med utleie?
              </Header>
              <p>
                Ja, sånn som situasjonen er i dag krever det mye tid og arbeid.{" "}
              </p>
              <p>
                FilmTing vil eliminere de tidkrevende tingene som tar fokuset
                bort fra det vi egentlig vil gjøre, og det vi tjener penger på,
                nemlig å være kreative filmskapere.
              </p>
              <Header size="small">
                Du forteller oss hvilket filmutstyr du har, også fikser vi
                resten.
              </Header>
            </Segment>
            <Segment className="mobilehidden">
              <Header size="medium">
                Følgende tjenester er under utvikling:
              </Header>
              <Tab
                menu={{
                  fluid: true,
                  vertical: true,
                  tabular: true,
                }}
                panes={panes}
              />
            </Segment>
            <Link to="/registrer">
              <FilmTingButton
                onClick={analytics.logEvent("sign-up-button-clicked", {
                  location: "tjeneste-beskrivelse",
                  action: "signup-interest",
                })}
              >
                <Icon name="signup"></Icon>
                Bli medlem av FilmTing
              </FilmTingButton>
            </Link>
          </Grid.Column>
          <Grid.Column
            width={5}
            className="image second mobilehidden"
          ></Grid.Column>
        </Grid>

        <Divider />

        <Grid columns={2} className="segmentmargin" stackable>
          <Grid.Column className="space-between">
            <Card fluid>
              <blockquote>
                <p>
                  <strong>
                    “Det forventes at markedet selv utvikler nye
                    forretningsmodeller som imøtekommer den digitale utviklingen
                    og medvirker til å skape et bærekraftig økonomisk fundament
                    for produksjon.“
                  </strong>
                </p>
                <cite>
                  Stortingsmelding 30 2014-2015: En framtidsrettet filmpolitikk
                </cite>
              </blockquote>
            </Card>
            <Card fluid>
              <blockquote>
                <p>
                  <strong>
                    “Kulturministeren har oppfordret aktørene selv til å utvikle
                    nye forretningsmodeller som tilpasses den digitale
                    utviklingen og bidrar til å skape et bærekraftig økonomisk
                    fundament for norsk innholdsproduksjon.”
                  </strong>
                </p>
                <cite>
                  Stortingsmelding 30 2014-2015: En framtidsrettet filmpolitikk
                </cite>
              </blockquote>
            </Card>
            <Card fluid>
              <blockquote>
                <p>
                  <strong>
                    “Film har også et næringspotensial som i større grad bør
                    realiseres.”
                  </strong>
                </p>
                <cite>
                  Stortingsmelding 30 2014-2015: En framtidsrettet filmpolitikk
                </cite>
              </blockquote>
            </Card>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment className="segmentmargin">
              <Header size="large">
                Basert på vår spørreundersøkelsen vi sendte ut til aktører i
                bransjen har vi funnet noen nøkkeltall:
              </Header>
              <List bulleted className="segmentmargin">
                <List.Item>
                  65% Av bransjen synes det er vanskelig eller veldig vanskelig
                  å finne utstyr når de skal leie fra andre.
                </List.Item>
                <List.Item>
                  64% Av bransjen har ikke eller vet ikke om de har forsikring
                  som dekker utstyrsleie.
                </List.Item>
                <List.Item>
                  79% Av filmbransjen får færre forespørsler om utleie enn de
                  ønsker.
                </List.Item>
              </List>

              <p className="segmentmargin">
                Investeringskostnadene for nytt utstyr er høye, spesielt når det
                står ubrukt mesteparten av tiden.
              </p>
              <p className="segmentmargin">
                Ved å øke synligheten og oversikten av tilgjengelig utstyr, vil
                frekvensen for leie øke. Ved å muliggjøre investering i bedre
                utstyr og infrastruktur, vil vi øke kapasiteten og
                produksjonsnivået til den Norske Filmbransjen, og vi vil i
                høyere grad kunne bistå de store internasjonale aktørene.
              </p>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid columns={2} className="segmentmargin" stackable>
          <Grid.Column className="image third mobilehidden"></Grid.Column>
          <Grid.Column>
            <Segment>
              <SignUp />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
