import React, { Component } from 'react'
import { Card, Image, Button } from 'semantic-ui-react'
import '../../../shared/styles/RoundedBottomRightCorner.scss';
import './EquipmentCard.scss';
import FilmTingButton from '../../../shared/filmting-button/filmting-button.component.jsx';
import { Highlight } from 'react-instantsearch-dom';

class EquipmentCard extends Component {

  render() {
    return (
      <Card className="rounded-bottom-right-corner card">
        <Image src={this.props.equipment.mainImageUrl} wrapped ui={false} className="cardImage"/>
        <Card.Content className="cardContent">
          <Card.Header><Highlight hit={this.props.equipment} attribute="nameLong" /></Card.Header>
          <p className="category-text" >{this.props.equipment.category.substring(0, this.props.equipment.category.length - 1)}</p>
          
        </Card.Content>
        <Card.Content extra>
          <div className="inline">
              <Button icon='info' className="roundButton"></Button>
              <FilmTingButton className="google-sign-in" onClick={this.props.addEquipmentClicked}>Legg til</FilmTingButton>
          </div>
        </Card.Content>
      </Card>
    )
  }
}

export default EquipmentCard