import React from 'react';
import { Link } from 'react-router-dom';
//import { Button } from 'semantic-ui-react';

import Notifications from '../../shared/sign-up-success/sign-up-success.component';
import FilmTingButton from '../filmting-button/filmting-button.component';
import { auth, analytics } from '../../../firebase/firebase.utils';

import {selectCurrentUser} from '../../../redux/user/user.selectors';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import './mainmenu.styles.scss';

const Header = ({ currentUser }) => (
    <div>
        <div className='topheader' >
            <div className='options'>
                <div>
                    {/* {
                        <Button as={Link} to='/' className='option ui button primary menu-button' animated='fade'>
                            <Button.Content visible>Logg inn</Button.Content>
                            <Button.Content hidden>Utilgjengelig</Button.Content>
                        </Button>
                    }
                    {<Register className='menu-button' />} */
                    }
                    {
                        currentUser ?
                            <FilmTingButton onClick={() => { auth.signOut() }}>
                                Logg ut
                            </FilmTingButton>
                            :
                            <Link to="/registrer">
                                <FilmTingButton onClick={() => {
                                    analytics.logEvent('signup-menu-button-clicked', { location: 'top-menu', action: 'signup-interest' });
                                }}>Registrer ny bruker</FilmTingButton>
                            </Link>
                    }
                </div>
            </div>
        </ div >
        {currentUser ? <Notifications /> : <div></div>}
    </div>
)

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
});


export default connect(mapStateToProps)(Header);
