import {combineReducers} from 'redux';

import userReducer from './user/user.reducer';
import addedEquipmentListReducer from './addedEquipmentList/addedEquipmentList.reducer';

const rootReducer = combineReducers({
    user: userReducer,
    addedEquipmentList: addedEquipmentListReducer
});

export default rootReducer;