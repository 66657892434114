import React from 'react';
import './sign-up-success.styles.scss';

export default class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            top: -100,
        }
    }


    render() {
        return (
            <div class="ui success message">
                {/* <i class="close icon"></i> */}
                <div class="header">Du er nå registrert som bruker!</div>
                <p>Vi sender deg en mail når vi åpner for å logge inn på plattformen.</p>
            </div>
        );
    }
}