import React, { Component } from 'react';
import NumberInput from 'semantic-ui-react-numberinput';
import { Icon } from 'semantic-ui-react'
import './ListItem.scss';

export default class ListItem extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        value: '1'
    };
    this.updateCounterState = this.updateCounterState.bind(this);
  }

  updateCounterState = (newValue) => { 
    this.setState({ value: newValue });
  }

  render() {
    return (
        <li className="addedEquipmentListElement">
            <img className="listImage" alt={this.props.item.nameLong+" thumbnail"} src={this.props.item.mainImageUrl}></img>  
            <div className="listItemContent">
                <p className="addedEquipmentListItemTitle">{this.props.item.nameLong || "Missing title"} </p>
                <p className="addedEquipmentListItemCategory" >{this.props.item.category || ""} </p>
                <div className="listItemBottomRow">
                    <NumberInput size="mini" className="numberInput inline-block" buttonPlacement="leftAndRight" value={this.state.value} onChange={this.updateCounterState} />  
                    <div onClick={this.props.removeClicked}><Icon name='remove' className="iconButton" size="large"/></div>
                </div>
            </div>
        </li>
    )
  }
}
