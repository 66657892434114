import React from 'react';
import ListItem from './ListItem/ListItem.component';
import './EquipmentList.scss'
import { useDispatch } from 'react-redux';
import { removeFromAddedEquipmentList } from '../../../../redux/addedEquipmentList/addedEquipmentList.actions';

function EquipmentList(props) {

  const dispatch = useDispatch(); //TODO: See if this can be moved one level up in the component hiarchy

  return (
      <ul className="addedEquipmentList">
          {props.addedEquipment.map(equipmentItem => (
              <ListItem item={equipmentItem} key={equipmentItem.objectID} removeClicked={() => dispatch(removeFromAddedEquipmentList(equipmentItem))}></ListItem>
          ))}
      </ul>
  );
}

//This component must be inside an Algolia InstantSearch component
export default EquipmentList;
