import * as actions from './addedEquipmentList.types';

export default function addedEquipmentListReducer(state = [], action) {
    switch (action.type) {
        case actions.EQUIPMENT_ADDED_TO_ADDED_EQUIPMENT_LIST:
            return [
                ...state,
                action.payload
            ];
        case actions.REMOVE_EQUIPMENT_FROM_ADDED_EQUIPMENT_LIST:
            return state.filter(equipment => equipment.objectID !== action.payload.objectID);
        default:
            return state;
    }
}
