import React from 'react';

function fourZeroFour() {
    return (
        <div>
            <p>oioioi, her har det skjedd noe rart. Denne siden eksisterer tydeligvis ikke.</p>
        </div>
    )
};

export default fourZeroFour;
