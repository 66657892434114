import React from 'react';
import './sign-in-and-sign-up.styles.scss';
import { Segment } from 'semantic-ui-react';

//import { Link } from 'react-router-dom';

//import SignIn from '../../shared/login/login.component';
import SignUp from '../../shared/register/register.component';

const SignInAndSignUpPage = () => (
    <div className="sign-in-and-sign-up">
        <Segment inline>
            <p className="introtext">Prosjektet er under utvilking, men du kan registrere bruker allerede nå. Da havner du først i køen når vi åpner for nye funksjoner.</p>
            <SignUp centered />
        </Segment>
    </div>



);

export default SignInAndSignUpPage;