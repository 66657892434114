import React, { Component } from 'react';
import './App.scss';
import { Redirect, Route, Switch } from 'react-router-dom';


import ReactGA from 'react-ga';
import { auth, createUserProfileDocument } from '../firebase/firebase.utils';

import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {setCurrentUser} from '../redux/user/user.actions';
import {selectCurrentUser} from '../redux/user/user.selectors';


import Mainmenu from '../components/shared/mainmenu/mainmenu.component';
import { WelcomePage } from '../components/pages/welcome/aboutpage.component';
import AddEquipmentPage from '../components/pages/addEquipment/AddEquipmentPage.component';
import fourZeroFour from '../components/pages/404/fourzerofour.component';
import SignInAndSignUpPage from '../components/pages/sign-in-and-sign-up/sign-in-and-sign-up.component';

const trackingId = "UA-157354218-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, { standardImplementation: true });
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  unsubscribeFromAuth = null


  componentDidMount() {

    const {setCurrentUser} = this.props;

    this.unsubsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot(snapShot => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data()
          });
        });
      }

      setCurrentUser(userAuth);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }


  render() {
    return (
      <div className="App">
        <div>
          <Mainmenu />
        </div>
        <Switch>
          <Route exact path='/' component={WelcomePage} />
          <Route 
            exact
            path='/registrer'
            render={() =>
              this.props.currentUser 
              ? (<Redirect to='/' />) 
              : (<SignInAndSignUpPage/>)  
            }
          />
          <Route exact path='/addEquipment' component={AddEquipmentPage} />
          <Route component={fourZeroFour} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});



export default connect(mapStateToProps,mapDispatchToProps)(App);
