import React from 'react';

import "./filmting-button.styles.scss";


const FilmTingButton = ({ className, children, onClick, otherProps }) => (
    <button
        //Turning off ESLint notification about unecessary string concatenation
        // eslint-disable-next-line 
        className={"default-filmting-button"+" "+(className || "")}
        onClick={onClick}
        {...otherProps}>
        {children}
    </button >
);

export default FilmTingButton;

