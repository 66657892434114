import * as actions from './addedEquipmentList.types';
  
export const addToAddedEquipmentList = (equipmentThing) => ({
    type: actions.EQUIPMENT_ADDED_TO_ADDED_EQUIPMENT_LIST,
    payload: equipmentThing
});

export const removeFromAddedEquipmentList = (equipmentThing) => ({
    type: actions.REMOVE_EQUIPMENT_FROM_ADDED_EQUIPMENT_LIST,
    payload: equipmentThing
});