import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/remote-config';

function isProdOrDev() {
    const currentURL = window.location.href;
    if (currentURL.includes("filmting.no")) {
        return "prod";
    }
    else {
        return "dev";
    }
}

function getCorrectConfig() {
    const environment = isProdOrDev();

    let config = {

    };

    if (environment === "dev") {
        config.apiKey = "AIzaSyCezfaafJRkYjG81Xu1xpP-0F1hKboTxT8";
        config.authDomain = "filmting-dev.firebaseapp.com";
        config.databaseURL = "https://filmting-dev.firebaseio.com";
        config.projectId = "filmting-dev";
        config.storageBucket = "filmting-dev.appspot.com";
        config.messagingSenderId = "751406040472";
        config.appId = "1:751406040472:web:bc064d17a0892c9a7ed7ed";
        config.measurementId = "G-QNZW37GBHD";
    }
    else if (environment === "prod") {
        config.apiKey = "AIzaSyCJcerYAfcFqJwTxtR464Vf7ce44UF4s00";
        config.authDomain = "filmting-prod.firebaseapp.com";
        config.databaseURL = "https://filmting-prod.firebaseio.com";
        config.projectId = "filmting-prod";
        config.storageBucket = "filmting-prod.appspot.com";
        config.messagingSenderId = "892850753521";
        config.appId = "1:892850753521:web:765c44c0474d41bfccfe50";
        config.measurementId = "G-P2F9B0GH0Q";
    }
    console.log("Currently on firebase " + environment + " environment.")

    return config;
}

firebase.initializeApp(getCorrectConfig());

let remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);

    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        const { email } = userAuth;
        const createdAt = new Date();

        try {
            await userRef.set({
                email, createdAt, ...additionalData
            })
        } catch (error) {
            console.log('Error creating user', error.message);
        }
    }
    return userRef;

};

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
