import React from 'react';
import "./register.styles.scss";

import FormInput from '../form-input/form-input.component';
import FilmTingButton from '../filmting-button/filmting-button.component';

import { withRouter } from 'react-router-dom';

import { auth, createUserProfileDocument, analytics } from '../../../firebase/firebase.utils';


class SignUp extends React.Component {
    constructor() {
        super();

        this.state = {
            displayName: '',
            bedriftsNavn: '',
            email: '',
            password: '',
            confirmPassword: ''
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        const { displayName, email, password, confirmPassword, bedriftsNavn } = this.state;

        if (password !== confirmPassword) {
            alert("Passord matcher ikke.");
            return;
        }

        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            await createUserProfileDocument(user, { displayName, bedriftsNavn });
            this.setState({
                displayName: '',
                email: '',
                password: '',
                confirmPassword: '',
                bedriftsNavn: ''
            });
            this.props.history.push('/');
        } catch (error) {
            console.error(error);
            alert(error.message);
        }
    };


    handleChange = event => {
        const { name, value } = event.target;

        this.setState(
            { [name]: value }
        )
    };


    render() {
        const { displayName, email, password, confirmPassword, bedriftsNavn } = this.state;
        return (
            <div className="sign-up">
                <h2 className='title'>Bli medlem av FilmTing</h2>
                <span>Lag ny bruker med epost og passord</span>
                <form className='signup-form' onSubmit={this.handleSubmit}>
                    <FormInput type='text' name='displayName' value={displayName} onChange={this.handleChange} label='Ditt Navn*' required />
                    <FormInput type='text' name='bedriftsNavn' value={bedriftsNavn} onChange={this.handleChange} label='Selskapsnavn' />
                    <FormInput type='email' name='email' value={email} onChange={this.handleChange} label='Epost*' required />
                    <FormInput type='password' name='password' value={password} onChange={this.handleChange} label='Passord*' required />
                    <FormInput type='password' name='confirmPassword' value={confirmPassword} onChange={this.handleChange} label='Bekreft Passord*' required />
                    <FilmTingButton type='submit' onClick={() => {
                        analytics.logEvent('new-user-signup', { location: 'signup-component', action: "signup" });
                    }}>Registrer ny bruker</FilmTingButton>
                </form>
            </div>


        )
    }

}

export default withRouter(SignUp);
