import React from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { Input } from 'semantic-ui-react'

const InputField =({ hits, currentRefinement, refine }) => (
    <Input icon='search' 
    placeholder='Søk...' 
    type="search" 
    value={currentRefinement}
    onChange={event => refine(event.currentTarget.value)}
    className="mainInput"/>
);

const AutoCompleteInputField = connectAutoComplete(InputField);

//This component must be inside an Algolia InstantSearch component
export default AutoCompleteInputField;